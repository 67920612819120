<template>
  <div :class="alertClasses" class="relative flex items-center px-4 py-3 text-sm rounded-lg font-manrope">
    <slot>
      <component :is="iconComponent" class="mr-6 size-8" />
    </slot>
    <span>{{ message }}</span>
  </div>
</template>

<script setup lang="ts">
import {
  AiOutlineInfoCircle,
  AiOutlineCheckCircle,
  AiOutlineWarning,
  AiOutlineCloseCircle,
} from "vue3-icons/ai";

interface Props {
  type: 'info' | 'success' | 'warning' | 'error';
  message: string,
  icon?: string | object | null;
}

const props = withDefaults(defineProps<Props>(), {
  type: "info",
  message: "",
  icon: null
})

const alertClasses = computed(() => {
  switch (props.type) {
    case "info":
      return "bg-blue-100 border-blue-400 text-blue-700";
    case "success":
      return "bg-[#57E26A4D] border-green-400 text-green-700";
    case "warning":
      return "bg-yellow-100 border-yellow-400 text-yellow-700";
    case "error":
      return "bg-red-100 border-red-400 text-red-700";
    default:
      return "";
  }
});

const iconComponent = computed(() => {
  if (props.icon) {
    return props.icon;
  }

  switch (props.type) {
    case "info":
      return AiOutlineInfoCircle;
    case "success":
      return AiOutlineCheckCircle;
    case "warning":
      return AiOutlineWarning;
    case "error":
      return AiOutlineCloseCircle;
    default:
      return null;
  }
});
</script>
